<template>
  <heard-and-footer>
    <title-name slot="title" :my-name="title"
                :my-background="require('../../assets/image/xinzhengjiedu.png')"></title-name>
    <div slot="content">
      <router-view></router-view>
    </div>
  </heard-and-footer>
</template>

<script>
import HeardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";
export default {
  name: "publicArtical",
  components: { HeardAndFooter,TitleName},
  data(){
    return{
      id:this.$route.query.id,
      title:''
    }
  },
  created() {
    this.$http.get('/safe/getColumnById',{
      params:{
        id:this.id
      }
    }).then((res)=>{
      this.title=res.data.title
    })
  }
}
</script>

<style scoped>

</style>